import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "kernel-terms-of-service",
      "style": {
        "position": "relative"
      }
    }}>{`Kernel Terms of Service`}<a parentName="h1" {...{
        "href": "#kernel-terms-of-service",
        "aria-label": "kernel terms of service permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Kernel is a custom educational community. We are building an open, peer-to-peer, lifelong network of awesome humans, one block at a time.`}</p>
    <p>{`Our primary goal is to`}<strong parentName="p">{` learn together`}</strong>{`. We achieve this by crafting peer-learning environments. These take the form of both events and software. Physical or online events hosted by Kernel are governed by our `}<a parentName="p" {...{
        "href": "/start/code-of-conduct"
      }}>{`code of conduct`}</a>{`.`}</p>
    <p>{`Collectively, we call any software Kernel creates “Services”. These Terms of Service apply to all Kernel Services, which can be found on the website hosted at `}<a parentName="p" {...{
        "href": "/"
      }}>{`www.kernel.community`}</a>{`, and any subdomain associated with it, for example wallet.kernel.community.`}</p>
    <p>{`These Terms of Use are a contract between you and Kernel. You agree to these terms by using any Kernel Service on any device. If you don’t agree to these terms, please don’t use our Services.`}</p>
    <h2 {...{
      "id": "our-principles-inform-our-decisions",
      "style": {
        "position": "relative"
      }
    }}>{`Our Principles Inform Our Decisions`}<a parentName="h2" {...{
        "href": "#our-principles-inform-our-decisions",
        "aria-label": "our principles inform our decisions permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`These terms are designed to reflect our Principles, while providing important legal protections for Kernel and all Kernel fellows.`}</p>
    <p>{`You agree to use Kernel Services in a way that promotes the `}<a parentName="p" {...{
        "href": "/start/principled-people"
      }}>{`Kernel Principles`}</a>{`.`}</p>
    <h2 {...{
      "id": "kernel-is-open-source",
      "style": {
        "position": "relative"
      }
    }}>{`Kernel Is Open-Source`}<a parentName="h2" {...{
        "href": "#kernel-is-open-source",
        "aria-label": "kernel is open source permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`The software we create is a public good. The Kernel Services source code is made available via free and open-source licenses, for anyone to share, modify, and benefit from (according to the applicable license). We believe in permission-less participation. Information regarding the current software running Kernel Services can be found `}<a parentName="p" {...{
        "href": "https://github.com/kernel-community/"
      }}>{`here`}</a>{`.`}</p>
    <h2 {...{
      "id": "your-keys",
      "style": {
        "position": "relative"
      }
    }}>{`Your Keys`}<a parentName="h2" {...{
        "href": "#your-keys",
        "aria-label": "your keys permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`You do not have to provide a real name when generating a Kernel Services account.`}</p>
    <p>{`When generating an account, a new key pair will be generated on your device. You will then be asked to associate any nickname of your choosing with your keys. Only you have access to your private keys, so you’re responsible for keeping them safe.`}</p>
    <p>{`We endeavor to provide as much information to guide you as we can, but Kernel doesn’t know what your keys are and cannot help you recover them if lost. Kernel will never have access to or control over your keys or wallet assets, as Kernel isn’t a custodian.`}</p>
    <h2 {...{
      "id": "we-care-about-your-privacy",
      "style": {
        "position": "relative"
      }
    }}>{`We Care About Your Privacy`}<a parentName="h2" {...{
        "href": "#we-care-about-your-privacy",
        "aria-label": "we care about your privacy permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Please be aware that any information posted to a blockchain is public. However, Kernel does not sell private fellow data, including messages and browser information, to advertisers or other third parties. Fellows may be asked to opt-in to share usage data for the purpose of helping us develop the software. `}</p>
    <p>{`Guided by our principles, Kernel is designed to protect your privacy and process as little personal data as possible. `}</p>
    <p>{`Learn more about how seriously we take privacy by reading our `}<a parentName="p" {...{
        "href": "/guiding/privacy"
      }}>{`Privacy Policy`}</a>{`.`}</p>
    <h2 {...{
      "id": "you-are-responsible",
      "style": {
        "position": "relative"
      }
    }}>{`You Are Responsible`}<a parentName="h2" {...{
        "href": "#you-are-responsible",
        "aria-label": "you are responsible permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`We teach responsible action and careful connection. Mutual aid enables individual autonomy and these terms are meant as an aid to help you understand the responsibilities you assume when using any Service we provide.`}</p>
    <p>{`You are responsible for the information you submit through any Service, including any harm resulting from that information, and Kernel shall not be liable for any acts or omissions by you.`}</p>
    <p>{`You’re responsible for taking precautions to protect yourself and your devices from viruses and other harmful content. You’re also responsible for any carrier or connection charges, fees, and taxes associated with the devices on which you use any Kernel Service.`}</p>
    <p>{`To use Kernel Services without parental permission, you must meet the minimum age requirements in your country of residence.`}</p>
    <p>{`When using any Service, you may be able to access, use, or interact with third party products and services, such as websites, blockchain wallet transactions, apps and DApps, support services, and group chat community spaces. When you use such third party services, please be aware that they are provided entirely by third party service providers and their terms and conditions, including privacy policies, govern your use of those services, and Kernel shall not be liable for your use of or activities on or with any third party service.`}</p>
    <h2 {...{
      "id": "conditions-of-use",
      "style": {
        "position": "relative"
      }
    }}>{`Conditions of Use`}<a parentName="h2" {...{
        "href": "#conditions-of-use",
        "aria-label": "conditions of use permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Unless otherwise expressly authorized in these Terms of Use or in a Service, you may not take any action to interfere with a Service or any other Fellow’s use of a Service, nor insert any code or product or manipulate the content in any way that affects any Fellow’s experience. While using any Service you are required to comply with all applicable statutes, orders, regulations, rules, and other laws. In addition, we expect users of our Services to respect the rights and dignity of others. Your use of our Services is conditioned on your compliance with the rules of conduct set forth in this Section.`}</p>
    <p>{`You may not use, or cause or encourage others to use, Kernel for any illegal, harmful, fraudulent, infringing, or objectionable activities. Here is a non-exhaustive list of prohibited activities:`}</p>
    <ol>
      <li parentName="ol">{`Collecting personally identifiable information `}</li>
      <li parentName="ol">{`Generating accounts through unauthorized means`}</li>
      <li parentName="ol">{`Installing any viruses, worms, malware, Trojan horses, or other harmful or destructive code or content`}</li>
      <li parentName="ol">{`Data mining, or other unsolicited promotional methods, machine generated content, or unethical or unwanted commercial content`}</li>
      <li parentName="ol">{`Spreading misinformation or misleadingly impersonating another person or company`}</li>
      <li parentName="ol">{`Using Kernel to try to generate "referred" site visits or click-through traffic`}</li>
      <li parentName="ol">{`Disrupting, manipulating, or degrading the operation of any website, product, or service`}</li>
      <li parentName="ol">{`Phishing, spamming, or pharming`}</li>
      <li parentName="ol">{`Unsolicited contacting of Fellows or other abusive behavior`}</li>
      <li parentName="ol">{`Advertising or marketing activities`}</li>
      <li parentName="ol">{`Infringing or misappropriating the rights of others`}</li>
      <li parentName="ol">{`Posting or transmitting any content that is illegal, fraudulent or otherwise objectionable, including content that constitutes child pornography, relates to bestiality, or depicts non-consensual sex acts`}</li>
      <li parentName="ol">{`Disrupting, manipulating, or impairing the operation of Kernel`}</li>
      <li parentName="ol">{`Creating a security risk for Kernel or any Kernel Fellow`}</li>
    </ol>
    <h2 {...{
      "id": "warranty-disclaimer",
      "style": {
        "position": "relative"
      }
    }}>{`Warranty Disclaimer`}<a parentName="h2" {...{
        "href": "#warranty-disclaimer",
        "aria-label": "warranty disclaimer permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`You expressly understand and agree that your use of these Services is at your sole risk. The Service is provided on an "AS IS" and "as available" basis, without warranties of any kind, either express or implied, including, without limitation, implied warranties of merchantability, fitness for a particular purpose or non-infringement. You release Kernel from all liability for content you acquired or failed to acquire through any Service.`}</p>
    <h2 {...{
      "id": "risks-you-should-know-about",
      "style": {
        "position": "relative"
      }
    }}>{`Risks You Should Know About`}<a parentName="h2" {...{
        "href": "#risks-you-should-know-about",
        "aria-label": "risks you should know about permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`You represent and warrant that you understand the inherent risks associated with cryptographic and economic systems and have an understanding of the usage and intricacies of virtual currency and tokens (such as Ether (ETH)), smart contract based tokens (such as ERC 20), and blockchain-based systems. You acknowledge and agree that: (i) Kernel and its representatives aren’t responsible for the underlying protocols of open source blockchain based networks, which may be subject to sudden changes in operating rules (such as forks), (ii) such changes may materially affect Kernel Services, and (iii) Kernel may, in our sole discretion, decide not to support a forked network. Kernel and its representatives aren’t responsible whatsoever for any underlying software protocols, whether forked or not.`}</p>
    <p>{`You acknowledge and understand that cryptographic and economic systems are an emerging technology with inherent risks. For example, advances in technology, such as cryptography or quantum computers, could result in the theft or loss of your tokens or wallet assets. While security is at the forefront of our priorities and Kernel intends to continuously update its security measures, Kernel can’t and doesn’t guarantee full security of any Kernel Service, its underlying protocols, or your tokens or wallet assets. Kernel has no ongoing duty to alert you to potential security risks.`}</p>
    <h2 {...{
      "id": "releases-you-should-read-closely",
      "style": {
        "position": "relative"
      }
    }}>{`Releases You Should Read Closely`}<a parentName="h2" {...{
        "href": "#releases-you-should-read-closely",
        "aria-label": "releases you should read closely permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`You release Kernel and its representatives from all liability related to any claim, cause of action, controversy, dispute, loss, or damages, known or unknown, related to, arising from, or in any way connected with: (i) user error, such as forgotten passwords, incorrectly constructed transactions, or mistyped virtual currency addresses; (ii) delays, server failure, or data loss; (iii) unauthorized access to any Kernel Service, your account, or your private data; (iv) bugs or other errors in Kernel Services; (v) any unauthorized third party activities, including without limitation the use of viruses, phishing, brute forcing, or other means of attack against Kernel Services; (vi) risks associated with cryptographic and economic systems, including the use of any token; (vii) fluctuations in transaction costs; (viii) loss of wallet assets; and (ix) your use of or activities on any third party service.`}</p>
    <h2 {...{
      "id": "kernels-liability-is-limited",
      "style": {
        "position": "relative"
      }
    }}>{`Kernel’s Liability Is Limited`}<a parentName="h2" {...{
        "href": "#kernels-liability-is-limited",
        "aria-label": "kernels liability is limited permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`In no event will Kernel or its representatives be liable under any contract, negligence, strict liability, or other legal or equitable theory for any lost profits, cost of procurement for substitute services, or any special, incidental, or consequential damages related to, arising from, or in any way connected with these terms, Kernel, or your use of any and all Kernel Services, even if Kernel or its representatives have been advised of the possibility of such damages. In any event, Kernel’s aggregate liability for such claims is limited to One Hundred Dollars (100 USD). These disclaimers and limitations of liability will apply to the maximum extent permitted by applicable law.`}</p>
    <h2 {...{
      "id": "you-indemnify-kernel-against-claims",
      "style": {
        "position": "relative"
      }
    }}>{`You Indemnify Kernel Against Claims`}<a parentName="h2" {...{
        "href": "#you-indemnify-kernel-against-claims",
        "aria-label": "you indemnify kernel against claims permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`You agree to indemnify and hold harmless Kernel and its representatives from and against any and all claims and expenses, including attorneys’ fees, arising out of your use of any Kernel Service, including without limitation your violation of these terms.`}</p>
    <h2 {...{
      "id": "where-kernel-operates",
      "style": {
        "position": "relative"
      }
    }}>{`Where Kernel Operates`}<a parentName="h2" {...{
        "href": "#where-kernel-operates",
        "aria-label": "where kernel operates permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Kernel Foundation is a Cayman Island foundation company that conducts global operations. Your use of any and all Kernel Services shall not subject Kernel or its representatives to jurisdiction in any such prohibited area under any circumstance. The Services provided by Kernel are not intended for distribution in any country where such distribution would subject Kernel to regulations or jurisdiction of a foreign court, and we reserve the right to limit distribution of any Kernel Service in any country.`}</p>
    <h2 {...{
      "id": "how-disputes-are-resolved",
      "style": {
        "position": "relative"
      }
    }}>{`How Disputes Are Resolved`}<a parentName="h2" {...{
        "href": "#how-disputes-are-resolved",
        "aria-label": "how disputes are resolved permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Whenever used in these terms, “dispute” has the broadest meaning enforceable by law and includes any claim you make against or controversy you may have with Kernel and its representatives.`}</p>
    <h2 {...{
      "id": "our-services-may-change",
      "style": {
        "position": "relative"
      }
    }}>{`Our Services May Change`}<a parentName="h2" {...{
        "href": "#our-services-may-change",
        "aria-label": "our services may change permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`As Kernel is an open-source, community-driven project, fellows can both request and contribute to feature development. Some or all of our Services may change or be discontinued at any time, including features or support for certain devices and platforms. Any and all new services or features added to Kernel Services are also subject to these terms.`}</p>
    <h2 {...{
      "id": "we-may-change-these-terms",
      "style": {
        "position": "relative"
      }
    }}>{`We May Change These Terms`}<a parentName="h2" {...{
        "href": "#we-may-change-these-terms",
        "aria-label": "we may change these terms permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`We may modify or replace any part of these terms. You’re responsible for checking our website periodically for any changes. Your continued use of any Kernel Service following posted changes to these terms means that you accept those changes.`}</p>
    <h2 {...{
      "id": "arbitration---please-read-carefully",
      "style": {
        "position": "relative"
      }
    }}>{`Arbitration - Please Read Carefully`}<a parentName="h2" {...{
        "href": "#arbitration---please-read-carefully",
        "aria-label": "arbitration   please read carefully permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`We usually prefer arbitration because we believe arbitration is more aligned with both our pedagogical methods and our principles. Litigation is seldom the best way to solve problems efficiently and with care.`}</p>
    <p>{`You agree to the following arbitration clause, which is essentially a waiver of your right to litigation and all opportunity to be heard by a judge. You understand that court review of an arbitration award is limited. You waive all your rights to a jury trial (if any) in any and all jurisdictions.`}</p>
    <p>{`All claims or disputes arising out of or in connection with this agreement, including the Services or any other engagement or dealings with Kernel, shall be referred to and finally determined by arbitration under the Rules of Arbitration of the International Chamber of Commerce (the `}<strong parentName="p">{`Rules`}</strong>{`), which Rules are incorporated by reference, by one (or more) arbitrator(s) appointed in accordance with the said Rules.`}</p>
    <p>{`Judgment on the award(s) rendered by the arbitrator(s) may be entered in any court having jurisdiction over Kernel, the client, or their assets.`}</p>
    <p>{`The seat, or legal place, of arbitration shall be the Cayman Islands.`}</p>
    <p>{`The terms of this agreement are governed by the laws of the Cayman Islands. You submit to the non-exclusive jurisdiction of the courts of the Cayman Islands in respect of any dispute arising out of or in connection with the terms of this agreement.`}</p>
    <p>{`The language to be used in the arbitral proceedings shall be English.`}</p>
    <p>{`Regardless of any applicable statute of limitations, any claim must be brought within one year after the claim arose or the time when you should have reasonably known about the claim.`}</p>
    <p>{`You waive the right to participate in a class action lawsuit or a classwide arbitration against Kernel or its representatives.`}</p>
    <h2 {...{
      "id": "what-happens-if-you-stop-using-kernel-services",
      "style": {
        "position": "relative"
      }
    }}>{`What Happens If You Stop Using Kernel Services`}<a parentName="h2" {...{
        "href": "#what-happens-if-you-stop-using-kernel-services",
        "aria-label": "what happens if you stop using kernel services permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`If, at any time, you no longer agree to these terms, you must stop using Kernel Services. Be aware that, even after logging out from all your devices and deleting your account, messages you sent may remain on the device of the recipient, blockchain transactions you made will remain recorded on the blockchain, and smart contracts you deployed to a blockchain will remain deployed.`}</p>
    <p>{`Even after you stop using Kernel Services, all provisions of these terms which by their nature should survive termination shall survive termination, including without limitation warranty disclaimers, indemnity, limitations of liability, dispute resolution, and governing law.`}</p>
    <h2 {...{
      "id": "about-these-terms",
      "style": {
        "position": "relative"
      }
    }}>{`About These Terms`}<a parentName="h2" {...{
        "href": "#about-these-terms",
        "aria-label": "about these terms permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`These terms cover the entire agreement between you and Kernel regarding the Kernel Services. If these terms are considered an offer by Kernel, acceptance is expressly limited to these terms.`}</p>
    <p>{`The captions and headings identifying sections and subsections of these terms are for reference only and don’t define, modify, expand, limit, or affect the interpretation of any provisions of these terms. We have provided illustrative links to websites for your information and education. Unless otherwise specifically indicated, neither those resources nor the information they contain are included in or part of these terms.`}</p>
    <p>{`If any part of these terms is held invalid or unenforceable, that part will be severable from these terms, and the remaining portions will remain in full force and effect. If we fail to enforce any of these terms, that doesn’t mean we’ve breached them or waive the right to enforce them.`}</p>
    <p>{`If you have questions about these terms, please contact us at `}<Link to="mailto:hello@kernel.community?subject=Terms%20of%20Service%20Query" mdxType="Link">{`hello`}{`[at]`}{`kernel.community`}</Link>{`.`}</p>
    <p>{`This document is `}<a parentName="p" {...{
        "href": "https://creativecommons.org/licenses/by-sa/4.0/"
      }}>{`CC-BY-SA`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      